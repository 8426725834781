import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import {
  CardServicies,
  CardServiciesInternet,
  CardServiciesNature,
  CardServiciesParking,
} from "../components/Cards";

import Layout from "../components/layout";
import Seo from "../components/seo";

const ServicesPage = () => {
  return (
    <Layout>
      <Seo title="Servicios" />
      <div className="grid grid-cols-1 lg:grid-cols-2 place-items-center">
        <div className="flex-col lg:mr-auto">
          <h2 className="title text-center lg:text-left lg:text-3xl max-w-lg">
            Encuentra en Villas Cheyos una casa que se adapte a tus necesidades.
          </h2>
          <div className="grid grid-cols-1 gap-4 lg:gap-0 lg:grid-cols-2 place-items-center lg:place-items-start pt-4">
            <CardServicies />
            <CardServiciesNature />
            <CardServiciesParking />
            <CardServiciesInternet />
          </div>
          <a href="https://wa.me/527531103093" className="">
            <button className="button-primary mx-auto lg:mx-0 lg:mr-auto mt-4 mb-8">
              Contactate
            </button>
          </a>
        </div>
        <div className="lg:ml-auto ">
          <div className="hidden lg:block">
            <StaticImage
              height={670}
              src="../images/VillasCheyos/entrance.png"
              placeholder="tracedSVG"
              alt="entrance image of Villas Cheyos"
              className="ml-auto"
            />
          </div>

          <div className="overflow-x-scroll snap-x flex gap-4 lg:hidden pb-4">
            <div className="snap-center flex-shrink-0 lg:hidden">
              <StaticImage
                src="../images/VillasCheyos/Mobile/2.png"
                placeholder="tracedSVG"
                alt="image of Nexpa"
                className="block h-full"
              />
            </div>
            <div className="snap-center flex-shrink-0 lg:hidden">
              <StaticImage
                height={348}
                src="../images/VillasCheyos/Mobile/3.png"
                placeholder="tracedSVG"
                alt="image of Nexpa"
                className="block h-full"
              />
            </div>
            <div className="snap-center flex-shrink-0 lg:hidden">
              <StaticImage
                height={348}
                src="../images/VillasCheyos/Mobile/4.png"
                placeholder="tracedSVG"
                alt="image of Nexpa"
                className="block h-full"
              />
            </div>
            <div className="snap-center flex-shrink-0 lg:hidden">
              <StaticImage
                height={348}
                src="../images/VillasCheyos/Mobile/5.png"
                placeholder="tracedSVG"
                alt="image of Nexpa"
                className="block h-full w-56"
              />
            </div>
            <div className="snap-center flex-shrink-0 lg:hidden">
              <StaticImage
                height={348}
                src="../images/VillasCheyos/Mobile/6.jpg"
                placeholder="tracedSVG"
                alt="image of Nexpa"
                className="block"
              />
            </div>
            <div className="snap-center flex-shrink-0 lg:hidden">
              <StaticImage
                height={348}
                src="../images/VillasCheyos/Mobile/7.jpg"
                placeholder="tracedSVG"
                alt="image of Nexpa"
                className="block"
              />
            </div>
            <div className="snap-center flex-shrink-0 lg:hidden">
              <StaticImage
                height={348}
                src="../images/VillasCheyos/Mobile/8.jpg"
                placeholder="tracedSVG"
                alt="image of Nexpa"
                className="block"
              />
            </div>
            <div className="snap-center flex-shrink-0 lg:hidden">
              <StaticImage
                height={348}
                src="../images/VillasCheyos/entrance.png"
                placeholder="tracedSVG"
                alt="image of Nexpa"
                className="block"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="hidden min-h-screen h-full lg:flex items-center justify-center pt-8">
        <StaticImage
          // height={1224}
          src="../images/VillasCheyos/VillasCheyosDesktop.png"
          placeholder="tracedSVG"
          alt="collage images of Villas Cheyos"
          className="w-full"
        />
      </div>
    </Layout>
  );
};

export default ServicesPage;
